﻿function initModals() {
    // Set up event listeners on buttons that open modals
    const modalTriggers = Array.from(document.querySelectorAll('[data-modal]'));
    const iframe = document.querySelector(".js-modal_iframe");
    const titleElement = document.querySelector(".js-modal_title");

    modalTriggers.map(function (trigger) {
        // Get the associated modal
        const targetModal = document.querySelector(trigger.dataset.modal);

        const hasError = Array.from(targetModal.querySelectorAll('.input-validation-error'));
        if (hasError.length > 0) {
            showModal(targetModal);
        };

        addCloseEventListener(targetModal);

        trigger.addEventListener('click', function (e) {
            e.preventDefault();
            //TODO : Add logic for capturing form data and send to  endpoints) e.g. newsletter signup
            if (iframe && trigger.dataset.videoId != null) {

                iframe.src = "https://www.youtube.com/embed/" + trigger.dataset.videoId + "?&autoplay=1";

                if (titleElement && trigger.dataset.videoTitle != null) {
                    titleElement.textContent = trigger.dataset.videoTitle;
                }

                showModal(targetModal);
            } else {
                showModal(targetModal);
            }
        });
    });

    // Set up event listeners for closing modals
    const modalHiders = Array.from(document.querySelectorAll('.js-close_modal'));
    modalHiders.map(function (modalHider) {
        modalHider.addEventListener('click', function (e) {
            e.preventDefault();
            // get the clicked element
            const el = e.currentTarget;
            // get the modal to close
            const modal = el.closest('.js-modal');

            if (titleElement) {
                titleElement.textContent = '';
            }
            if (iframe) {
                iframe.src = '';
            }
            closeModal(modal);
        });
    });
}

initModals();

/**
 * 
 * @param {string} targetModal : ID of the target modal 
 */
function showModal(modal) {
    modal.removeAttribute('hidden');
    modal.removeAttribute('aria-hidden');
    modal.classList.add('is-visible');
}

function closeModal(modal) {
    var iframe = document.querySelector(".js-modal_iframe");
    var titleElement = document.querySelector(".js-modal_title");
    if (titleElement) {
        titleElement.textContent = '';
    }
    if (iframe) {
        iframe.src = '';
    }
    modal.setAttribute('hidden', ''); // hide visually
    modal.setAttribute('aria-hidden', ''); // hide from screenreaders
    modal.classList.remove('is-visible');
}


function addCloseEventListener(modal) {
    //On escape press close modal
    document.addEventListener('keydown', function (e) {
        let keyCode = e.keyCode;
        if (keyCode === 27) {
            closeModal(modal);
        }
    });
}

